<template>
  <div class="referrals" :style="pages > 1 ? 'padding-bottom: 0;' : ''">
    <div class="filters" style="flex-wrap: nowrap;">
      <div class="search">
        <label for="search">Cerca</label>
        <input id="search" type="text" name="" placeholder="Nome, Cognome, Azienda, Campagna" @input="filterSearch($event)">
      </div>

      <!-- <searchBar :search="search" @update:search="filterSearch($event)" /> -->

      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" type="date" @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" type="date" @input="dateToChanged($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>

      <div class="btn-confirm" style="width: min-content;">
        <material-button type="danger" text="Rifiuta candidatura" :disabled="isBtnRejectDisabled()" @click="btnRejectOnClick()" />
      </div>

      <div v-if="permissions.canUse('ANONYMIZED_CANDIDATE_CONFIRM')" class="btn-confirm" style="width: min-content;">
        <material-button :text="selectedRowsId.length > 1 ? 'Conferma candidature' : 'Conferma candidatura'" :disabled="btnConfirmDisabled()" @click="btnConfirmOnClick()" />
        <span v-show="selectedRowsId.length > 1" class="counter-badge">
          {{ selectedRowsId.length }}
        </span>
      </div>
    </div>

    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="checkbox">
              <input id="mainCheckbox" type="checkbox" @click="selectAllAnonimyzedRows()">
            </th>
            <th v-for="header of headers" :key="header" class="orderable" @click="changeOrder(header.field)">
              <div class="">
                <span>{{ header.title }}</span>
                <font-awesome-icon v-if="(filters.order.field == header.field)" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="row in getFilteredRows()" :key="row.id">
            <td class="checkbox relative">
              <span v-if="row.selfSuggest" class="self-suggest absolute">{{ row.selfSuggest }}</span>
              <!-- <input :disabled="!row.referral.cvAnonId.dateUploadAnonCv" type="checkbox" @click="selectRow(row.id)"> -->
              <input type="checkbox" @click="selectRow(row.id)">
            </td>

            <!-- Nome cognome inseriti dal suggerito -->
            <td>
              <div>
                <Tooltip :text="(row.referral.nameRefferedCandidate + ' ' + row.referredSurname)" :disabled="(row.referral.nameRefferedCandidate + ' ' + row.referredSurname).length < 21">
                  <span>
                    {{ getCandidateName(row.referral.nameRefferedCandidate, row.referredSurname) }}
                  </span>
                </Tooltip>
              </div>
              <div style="margin-top:5px">
                <Tooltip :text="row.referral.mailRefferedCandidate" :disabled="row.referral.mailRefferedCandidate.length < 21">
                  <span class="text-xs">
                    {{ getCandidateEmail(row.referral.mailRefferedCandidate) }}
                  </span>
                </Tooltip>
              </div>
            </td>

            <!-- Nome cognome specificati al suggerimento dal suggeritore -->
            <td>
              <div>
                <Tooltip :text="(row.referredName + ' ' + row.referredSurname)" :disabled="(row.referredName + ' ' + row.referredSurname).length < 21">
                  <span>
                    {{ getCandidateName(row.referredName, row.referredSurname) }}
                  </span>
                </Tooltip>
              </div>
              <div style="margin-top:5px">
                <Tooltip :text="row.referredEmail" :disabled="row.referredEmail.length < 21">
                  <span class="text-xs">
                    {{ getCandidateEmail(row.referredEmail) }}
                  </span>
                </Tooltip>
              </div>
            </td>

            <td>
              <div>
                <slot v-if="row.referral.referralWorkitem?.actionType !== 'candidate_rejected'">
                  <p>
                    {{ dayjs.utc(row.applicationDate, 'YYYY-MM-DDTHH:mm:ss').local().format('DD MMMM YYYY') }}
                  </p>
                  <p style="font-size:13px">
                    {{ dayjs.utc(row.applicationDate, 'YYYY-MM-DDTHH:mm:ss').local().format('HH:mm:ss') }}
                  </p>
                </slot>
                <slot v-else>
                  <p>
                    {{ dayjs.utc(getActionDataDate(row), 'DD-MM-YYYY').local().format('DD MMMM YYYY') }}
                  </p>
                  <p v-if="dayjs.utc(getActionDataDate(row), 'DD-MM-YYYY HH:mm').isValid()" style="font-size:13px">
                    {{ dayjs.utc(getActionDataDate(row), 'DD-MM-YYYY HH:mm').local().format('HH:mm:ss') }}
                  </p>
                </slot>
              </div>
            </td>

            <td>
              <Tooltip :text="row.referral.campaignName" :disabled="row.referral.campaignName < 21">
                <span>
                  {{ getName(row.referral.campaignName) }}
                </span>
              </Tooltip>
            </td>
            <td>
              {{ row.referral.companyName }}
            </td>
            <td class="file-link">
              <div class="inline-row cursor-pointer" @click="downloadPDF(row.referral, 0, row)">
                <span style="display:flex;align-items:center">
                  <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" />
                  </svg>
                  <span class="text-underline"> {{ getCVName(row.referral, 'cv', row) }}</span>
                </span>
              </div>
            </td>
            <td class="file-link" :style="row.referral.cvAnonId.dateUploadAnonCv ? '' : 'text-align:center'">
              <div v-if="row.referral.cvAnonId.dateUploadAnonCv">
                <div class="inline-row cursor-pointer">
                  <span class="inline-row cursor-pointer" @click="downloadPDF(row.referral, 1, row)">
                    <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M17.06 13C15.2 13 13.64 14.33 13.24 16.1C12.29 15.69 11.42 15.8 10.76 16.09C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17C3 19.21 4.77 21 6.94 21C9 21 10.68 19.38 10.84 17.32C11.18 17.08 12.07 16.63 13.16 17.34C13.34 19.39 15 21 17.06 21C19.23 21 21 19.21 21 17C21 14.79 19.23 13 17.06 13M6.94 19.86C5.38 19.86 4.13 18.58 4.13 17S5.39 14.14 6.94 14.14C8.5 14.14 9.75 15.42 9.75 17S8.5 19.86 6.94 19.86M17.06 19.86C15.5 19.86 14.25 18.58 14.25 17S15.5 14.14 17.06 14.14C18.62 14.14 19.88 15.42 19.88 17S18.61 19.86 17.06 19.86M22 10.5H2V12H22V10.5M15.53 2.63C15.31 2.14 14.75 1.88 14.22 2.05L12 2.79L9.77 2.05L9.72 2.04C9.19 1.89 8.63 2.17 8.43 2.68L6 9H18L15.56 2.68L15.53 2.63Z" />
                    </svg>
                    <span class="text-underline"> {{ getCVName(row.referral, 'anonimo', row) }} </span>
                  </span>
                  <div class="box ml-auto" @click="startDeleteCV(row.referral.id, row.referral.cid, row.referral.uidReferred, row.referral.cvAnonId.id)">
                    <font-awesome-icon icon="trash-can" style="color: #c84d4d" />
                  </div>
                </div>
              </div>
              <button v-else-if="permissions.canUse('ANONYMIZE_CV')" class="addCv cursor-pointer" @click="startUploadCV(row.referral)" v-text="'Aggiungi CV anonimizzato'" />
            </td>
            <td>
              <span v-if="row.referral?.cvAnonId?.uidAnomizer?.firstName">
                {{ row.referral.cvAnonId.uidAnomizer.firstName }}
              </span>

              <span v-if="row.referral?.cvAnonId?.uidAnomizer?.lastName">
                {{ ' ' + row.referral.cvAnonId.uidAnomizer.lastName }}
              </span>

              <span v-if="!(row.referral?.cvAnonId?.uidAnomizer?.firstName || row.referral?.cvAnonId?.uidAnomizer?.lastName)" style="width:100%; text-align:center">
                -
              </span>
            </td>
            <td>
              <div>
                <Tooltip :text="(row.referralName + ' ' + row.referralSurname)" :disabled="(row.referralName + ' ' + row.referralSurname).length < 21">
                  <span>
                    {{ getCandidateName(row.referralName, row.referralSurname) }}
                  </span>
                </Tooltip>
              </div>
              <div style="margin-top:5px">
                <Tooltip :text="row.referralEmail" :disabled="row.referralEmail.length < 21">
                  <span class="text-xs">
                    {{ getCandidateEmail(row.referralEmail) }}
                  </span>
                </Tooltip>
              </div>
            </td>
            <td>
              <div v-html="getDate(row.referral.creationDate)" />
            </td>
            <td class="rejected" :class="{ 'cursor-pointer': isRowRejected(row) }" @click="openRejectedInfoModal(row.referral.referralWorkitem)">
              <font-awesome-icon v-if="isRowRejected(row)" icon="check" style="color: orangered" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="pages > 1" class="pagination">
      <div>Visualizzati {{ numberOfElements }} di {{ totalElements }}</div>
      <v-pagination v-model="page" :pages="pages" active-color="#bcde42" @update:modelValue="getReferrals" />
    </div>

    <input
      id="fileInput"
      type="file"
      name=""
      style="display: none"
      accept=".pdf, .doc, .docx"
      @change="uploadCV($event)"
    >
  </div>

  <Dialog
    ref="dialog"
    title="Vuoi Confermare?"
    :subtitle="selectedRowsId.length > 1 ? `se confermi, ${selectedRowsId.length} candidati verranno postati` : 'se confermi, il candidato verrà postato'"
    button-text="Continua"
    :image-src="image"
    @buttonAction="publishSelected"
  />

  <Dialog
    ref="pdfPreviewDialog"
    :pdf-url="pdfUrlToOpen"
    size="preview"
    :editable="true"
    :redactions="redactions"
    @saveAction="uploadBlob"
  />

  <Dialog ref="confirmDelete" title="Eliminare il curriculum anonimizzato?" size="small" button-text="Elimina" @buttonAction="confirmDeleteCV" />

  <Dialog ref="pdfSizeError" title="Il file caricato è troppo grande!" subtitle="(Limite 5MB)" button-text="Ok" @buttonAction="() => { $refs.pdfSizeError.close() }" />

  <div v-show="rejectSuggestionModal !== 'hidden'" class="dialog">
    <div class="dialog-content">
      <form class="flex flex-column gap-1 custom-inputs" autocomplete="off" @submit.prevent @keydown.enter.prevent="">
        <strong v-if="rejectSuggestionModal === 'readonly'" style="margin-bottom: 1rem">Rifiutato da {{ rejectSuggestionUser }} - {{ rejectSuggestionDate }} </strong>
        <strong v-else style="margin-bottom: 1rem">Rifiuta suggerimento: </strong>

        Messaggio per il suggeritore:
        <div class="flex">
          <div class="flex-1">
            <textarea
              v-model="rejectSuggestionMessageReferral"
              placeholder="Aggiungi un messaggio per il suggeritore"
              :class="{ 'textarea-border-red': rejectSuggestionValidation }"
              rows="3"
              class="w-full"
              :disabled="rejectSuggestionModal === 'readonly'"
              @keydown.enter.prevent
            />
          </div>
        </div>

        Messaggio per il suggerito:
        <div class="flex">
          <div class="flex-1">
            <textarea
              v-model="rejectSuggestionMessageReferred"
              placeholder="Aggiungi un messaggio per il suggerito"
              :class="{ 'textarea-border-red': rejectSuggestionValidation }"
              rows="3"
              class="w-full"
              :disabled="rejectSuggestionModal === 'readonly'"
              @keydown.enter.prevent
            />
          </div>
        </div>

        <div v-if="rejectSuggestionModal == 'readonly'" class="flex gap-1 justify-space-between">
          <div>
            <p>Azione suggerito:</p>
            <p>{{ rejectSuggestionAction }}</p>
          </div>

          <material-button text="CHIUDI" type="light" @click="rejectSuggestionModal = 'hidden'" />
        </div>

        <div v-else class="flex gap-1">
          <div class="flex-1 flex gap-1 flex-end">
            <material-button text="ANNULLA" type="light" @click="rejectSuggestionModal = 'hidden'" />
            <material-button text="RIFIUTA" type="info" @click="confirmRejectSuggestion()" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Dialog from '../components/Dialog.vue'
import Tooltip from '../components/Tooltip.vue'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { sidebarStore } from '@/stores/sidebar'
import { navbarStore } from '@/stores/navbar'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import { permissionsStore } from '@/stores/permissions'

import dayjs from 'dayjs'
require('dayjs/locale/it')
dayjs.locale('it')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default {

  components: {
    Dialog,
    Tooltip,
    VPagination
  },
  data() {
    return {
      referrals: [],
      selectedRowsId: [],
      filters: {
        order: {
          field: 'applicationDate',
          asc: false
        },
        search: '',
        dateFrom: null,
        dateTo: null
      },
      lastRowClicked: null,
      newUploadName: null,
      pdfUrlToOpen: null,
      deleteData: null,
      rejectSuggestionModal: 'hidden',
      rejectSuggestionValidation: false,
      rejectSuggestionMessageReferral: '',
      rejectSuggestionMessageReferred: '',
      rejectSuggestionUser: '',
      rejectSuggestionDate: '',
      rejectSuggestionAction: '',
      headers: [
        { title: 'Dati invito', field: 'referralName' },
        { title: 'Dati candidatura', field: '' },
        { title: 'Data candidatura', field: 'applicationDate' },
        { title: 'Campagna', field: 'campaign_name' },
        { title: 'Azienda', field: 'company_name' },
        { title: 'CV caricato', field: '' },
        { title: 'CV anonimizzato', field: '' },
        { title: 'Anonimizzato da', field: '' },
        { title: 'Suggerito da', field: '' },
        { title: 'Data suggerimento', field: '' },
        { title: 'Rifiutato', field: '' }
      ],
      redactions: {
        name: null,
        lastname: null,
        email: null
      },
      page: 1,
      pages: 0,
      typingTimer: null,
      totalElements: 0,
      numberOfElements: 0,
      spinner: spinnerStore(),
      sidebar: sidebarStore(),
      navbar: navbarStore(),
      image: require('../../static/check-circle.png'),
      permissions: permissionsStore(),
      dayjs
    }
  },

  mounted() {
    this.navbar.title = 'Anonimizzazione'

    this.getReferrals()
  },

  methods: {
    btnConfirmDisabled() {
      if (this.selectedRowsId.length === 0) return true

      const selectedRows = this.referrals.filter((referral) => this.selectedRowsId.includes(referral.id))
      // if no rows does not have dateUploadAnonCv, disable the button
      if (selectedRows.some((r) => !r.referral.cvAnonId.dateUploadAnonCv)) return true
      return false
    },
    getReferrals() {
      this.spinner.show()
      sbapibackoffice
        .get('/referral/check', {
          withCredentials: true,
          params: {
            page: this.page - 1,
            size: 10,
            sort: `${this.filters.order.field},${this.filters.order.asc ? 'asc' : 'desc'}`,
            search: this.filters.search || undefined,
            startDateFilter: this.filters.dateFrom ? dayjs(this.filters.dateFrom).format('YYYY-MM-DD') : undefined,
            endDateFilter: this.filters.dateTo ? dayjs(this.filters.dateTo).format('YYYY-MM-DD') : undefined
          }
        })
        .then(({ data }) => {
          // filter not published
          this.referrals = data.content
          localStorage.setItem('referrals', data.totalElements)

          this.pages = data.totalPages
          this.totalElements = data.totalElements
          this.numberOfElements = data.numberOfElements

          this.checkSelfSuggest()
        })
        .catch(({ response }) => {
          if (response) {
            if (response.status == 401) this.$router.push({ path: '/login' })
            else this.$toast.error('Qualcosa è andato storto')

            //this.$toast.error(response.data);
          }
        })
        .then(() => {
          this.spinner.hide()
        })
    },
    /** Check if a user is self suggested */
    checkSelfSuggest() {
      // cycle all referrals and check if the user is self suggested
      this.referrals.forEach((referral) => {
        if (referral.referralName === referral.referral.nameRefferedCandidate && referral.referralSurname === referral.referredSurname) {
          referral.selfSuggest = 'Nome e cognome uguali'
        }

        if (referral.referralEmail === referral.referral.mailRefferedCandidate) {
          referral.selfSuggest = 'Email uguale'
        }
      })
    },
    getCandidateName(name, surname) {
      let n = name + ' ' + surname
      if (n.length > 25) {
        n = n.slice(0, 22)
        n = n + '...'
      }
      return n
    },
    getCandidateEmail(email) {
      let e = email
      if (email.length > 25) {
        e = email.slice(0, 22)
        e = e + '...'
      }
      return e
    },
    getDate(date) {
      return dayjs.utc(date, 'YYYY-MM-DDTHH:mm:ss').local().format('DD MMMM YYYY') + '<p style="font-size:13px">' + dayjs.utc(date, 'YYYY-MM-DDTHH:mm:ss').local().format('HH:mm:ss') + '</p>'
    },
    getCandidateCv(cv) {
      let c = cv
      if (cv.length > 15) {
        c = cv.slice(0, 13)
        c = c + '...'
      }
      return c + '.CV'
    },
    uploadBlob(blob, fileName) {
      let formData = new FormData()
      formData.append('file', blob, this.newUploadName ?? fileName ?? 'upload.pdf')

      this.spinner.show()
      sbapibackoffice
        .post('/anonymized/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true,
          params: {
            anonId: this.lastRowClicked
          }
        })
        .then(({ status }) => {
          if (status == 200) {
            const element = this.referrals.find((f) => f.referral.cvAnonId.id == this.lastRowClicked)?.referral

            element.cvAnonId.dateUploadAnonCv = true

            if (!element.cvAnonId.uidAnomizer) element.cvAnonId.uidAnomizer = {}
            element.cvAnonId.uidAnomizer.firstName = localStorage.getItem('user')

            this.$toast.success('CV aggiunto')
          }
        })
        .catch((error) => {
          this.$toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => {
          this.spinner.hide()
          document.getElementById('fileInput').value = null
        })
    },
    /** select all visible rows */
    selectAllAnonimyzedRows() {
      const isChecked = document.getElementById('mainCheckbox').checked

      document.querySelectorAll('.table-body > tr > .checkbox > input[type="checkbox"]:not([disabled])').forEach((r) => {
        r.checked = isChecked
      })

      // if the main checkbox is selected then select all anonimyzed rows
      if (isChecked)
        this.selectedRowsId = this.getFilteredRows()
          .filter((f) => f.cvAnonId.dateUploadAnonCv)
          .map((m) => m.id)
      else this.selectedRowsId = []
    },

    /**
     * select a row by id
     * @param  {number} referralId referral ID
     */
    selectRow(referralId) {
      // remove from list if the row is already selected
      if (this.selectedRowsId.find((id) => id == referralId)) this.selectedRowsId = this.selectedRowsId.filter((id) => id != referralId)
      else this.selectedRowsId.push(referralId)
    },

    /**
     * Set the order condition for the table
     * @param {number} orderBy
     * 0 Date
     * 1 Name
     * 2 Company
     * 3 Campaign
     */
    changeOrder(orderBy) {
      if (this.filters.order.field == orderBy) {
        this.filters.order.asc = !this.filters.order.asc
      } else {
        this.filters.order.field = orderBy
        this.filters.order.asc = true
      }

      this.getReferrals()
    },

    filterSearch({ target }) {
      this.filters.search = target.value

      // execute getReferrals after 500ms of non typing
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        this.page = 1
        this.getReferrals()
      }, 1000)
    },

    getFilteredRows() {
      const rows = this.referrals
      return rows
    },

    startUploadCV(referral) {
      this.lastRowClicked = referral?.cvAnonId.id
      this.newUploadName = referral.cid + '_' + referral.uidReferred + '.pdf'
      document.getElementById('fileInput').click()
    },

    uploadCV({ target }) {
      const file = target.files[0]
      if (!file) return

      if (file.size > 5 * 1024 * 1024) {
        this.$refs.pdfSizeError.open()
      } else {
        // var blob = new Blob([file], {
        // 	type: "application/pdf; charset=utf-8",
        // });

        this.uploadBlob(new Blob([file]), file.name)
      }
    },

    startDeleteCV(id, cid, uid, anonId) {
      this.$refs.confirmDelete.open()
      this.deleteData = {
        id,
        cid,
        uid,
        anonId
      }
    },
    getCVName(candidate, type, row) {
      let name = ''
      if (type === 'cv') {
        name = row.referredSurname
        if (name.length > 10) {
          name = name.slice(0, 7)
          name = name + '...'
        }

      } else {
        name = candidate.cid + '_' + candidate.uidReferred
      }
      name = name + '.CV'
      return name
    },
    getName(name) {
      let c = name
      if (c.length > 20) {
        c = c.slice(0, 17)
        c += '...'
      }
      return c
    },
    confirmDeleteCV() {
      this.$refs.confirmDelete.close()
      this.spinner.show()

      sbapibackoffice
        .delete('/anonymized/delete', {
          withCredentials: true,
          data: {
            cid: this.deleteData.cid,
            uid: this.deleteData.uid,
            anonId: this.deleteData.anonId
          }
        })
        .then(({ status }) => {
          if (status == 200) {
            const element = this.referrals.find((f) => f.id == this.deleteData.id)?.referral

            element.cvAnonId.dateUploadAnonCv = null
            element.cvAnonId.uidAnomizer.firstName = null
            element.cvAnonId.uidAnomizer.lastName = null

            this.$toast.success('CV eliminato')
          }
        })
        .catch((error) => {
          this.$toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => {
          this.spinner.hide()
        })
    },

    btnConfirmOnClick() {
      this.$refs.dialog.open()
    },

    btnRejectOnClick() {
      this.rejectSuggestionMessageReferral = ''
      this.rejectSuggestionMessageReferred = ''
      this.rejectSuggestionUser = ''
      this.rejectSuggestionDate = ''
      this.rejectSuggestionAction = ''
      this.rejectSuggestionModal = 'visible'
      this.rejectSuggestionValidation = false
    },

    confirmRejectSuggestion() {
      // check if at least one of the two fields is filled
      if (!this.rejectSuggestionMessageReferral && !this.rejectSuggestionMessageReferred) {
        this.$toast.error('Inserisci almeno un messaggio')
        this.rejectSuggestionValidation = true
        return
      }

      this.spinner.show()
      sbapibackoffice
        .post('/referral/reject', {
          idReferral: this.selectedRowsId[0],
          messageReferral: this.rejectSuggestionMessageReferral,
          messageReferred: this.rejectSuggestionMessageReferred
        })
        .then(({ status }) => {
          if (status == 200) {
            this.$toast.success('Suggerimento rifiutato')
            this.rejectSuggestionModal = 'hidden'
            this.getReferrals()
          }
        })
        .catch((error) => {
          this.$toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => {
          this.spinner.hide()
        })
    },

    /**
     * Open the modal to show the rejected info
     * @param {string} actionData object stringified with the data to show
     */
    openRejectedInfoModal({ actionData, wiUser, actionType }) {
      if (!actionData) return

      const data = JSON.parse(actionData)
      this.rejectSuggestionMessageReferral = data.find(f => f.name === 'messageReferral')?.value
      this.rejectSuggestionMessageReferred = data.find(f => f.name === 'messageReferred')?.value
      this.rejectSuggestionUser = wiUser.firstName + ' ' + wiUser.lastName
      // this.rejectSuggestionDate = dayjs.utc(data.find(f => f.name === 'candidate_rejected_data')?.value, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm')

      if (data.find(f => f.name === 'backoffice_rejected_data')) {
        this.rejectSuggestionDate = dayjs.utc(data.find(f => f.name === 'backoffice_rejected_data')?.value, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm')
      } else {
        this.rejectSuggestionDate = ''
      }

      switch (actionType) {
        case 'rejected': this.rejectSuggestionAction = 'Rifiutato da backoffice'; break
        case 'candidate_confirmed': this.rejectSuggestionAction = 'Candidatura spontanea confermata'; break
        case 'candidate_rejected': this.rejectSuggestionAction = 'Candidatura rifiutata da email'; break
        case 'candidate_suggested': this.rejectSuggestionAction = 'Invito creato'; break
      }

      this.rejectSuggestionModal = 'readonly'
      this.rejectSuggestionValidation = false
    },

    getActionDataDate(row) {
      return JSON.parse(row.referral.referralWorkitem.actionData).find(f => f.name === 'candidate_campaign_data')?.value
    },

    publishSelected() {
      var promises = []
      for (const rowId of this.selectedRowsId) {
        promises.push(
          new Promise((resolve, reject) => {
            const anonId = this.referrals.find((f) => f.id == rowId)?.referral.cvAnonId.id

            sbapibackoffice
              .put(
                '/anonymized/published',
                {},
                {
                  withCredentials: true,
                  params: {
                    anonId
                  }
                }
              )
              .then(({ status, data }) => {
                // remove the candidate if it has been successfully published
                if (status == 200) {
                  this.$toast.success('Candidato pubblicato')

                  this.referrals = this.referrals.filter((f) => f.id != rowId)
                  window.dispatchEvent(new CustomEvent('storage-referrals', {
                    detail: {
                      storage: this.totalElements
                    }
                  }))
                  this.selectedRowsId = this.selectedRowsId.filter((f) => f != rowId)

                  return resolve(rowId)
                } else {
                  this.$toast.error(data)
                  return reject(data)
                }
              })
              .catch((error) => {
                if (error?.response?.data?.messageCode == 'PUB-000') {
                  this.$toast.error('l\' invito non può essere pubblicato perchè è stato annullato')
                } else {
                  this.$toast.error('Qualcosa è andato storto')
                }
                return reject(error)
              })
          })
        )
      }

      Promise.all(promises)
        .then(
          (resolve) => {
            console.log(resolve)
            //close modal
            this.$refs.dialog.close()
          },
          (reject) => {
            console.log(reject)
          }
        )
        .then(() => {
          this.spinner.hide()
        })
    },

    dateFromChanged({ target }) {
      this.filters.dateFrom = target.value ? new Date(target.value) : undefined
      this.page = 1
      this.getReferrals()
    },

    dateToChanged({ target }) {
      this.filters.dateTo = target.value ? new Date(target.value) : undefined
      this.page = 1
      this.getReferrals()
    },

    downloadPDF(referral, type = 0, row) {
      const cid = referral.cid
      const uid = referral.uidReferred
      const anonId = referral.cvAnonId.id

      this.redactions.name = referral.nameRefferedCandidate
      this.redactions.lastname = row.referredSurname
      this.redactions.email = referral.mailRefferedCandidate

      this.lastRowClicked = anonId
      this.spinner.show()
      sbapibackoffice
        .get('/referred/document/url', {
          withCredentials: true,
          params: {
            cid,
            uid,
            type
          }
        })
        .then(({ data }) => {
          this.pdfUrlToOpen = data
          this.$refs.pdfPreviewDialog.open(this.pdfUrlToOpen)
          //window.open(data, "_blank").focus();
        })
        .catch((error) => {
          this.$toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => {
          this.spinner.hide()
        })
    },

    isBtnRejectDisabled() {
      if (this.selectedRowsId.length === 0) return true
      if (this.selectedRowsId.length > 1) return true

      const row = this.referrals.find((f) => f.id == this.selectedRowsId[0])

      if (!row) return true

      return this.isRowRejected(row)
    },

    isRowRejected(row) {
      return row.referral.referralWorkitem && row.referral.referralWorkitem.actionType !== 'candidate_suggested'
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/referrals';
@use '../assets/scss/table';
@use "../assets/scss/dialog"; // general dialog styles

.addCv {
  max-width: 130px;
  min-width: unset !important;
  overflow: visible !important;
  white-space: normal;
  line-height: unset !important;
  font-size: 10.5px;
  background: var(--info);
  color: white;
  text-transform: uppercase;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  border: 2px solid var(--info);
}

.text-xs {
  font-size: 13px;
}

.rejected {
  text-align: center;
  font-size: 1.25rem !important;

  &:hover {
    svg {
      scale: 125%;
    }
  }
}
</style>

<style lang="scss">
.pagination {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul.Pagination {
    justify-content: flex-end;

    li.PaginationControl>path {
      fill: rgba(255, 255, 255, 0.233);
    }

    .Control-active {
      fill: white;
    }

    .Page {
      width: 25px;
      height: 25px;
      color: white;
    }

    button.Page-active {
      background: var(--accented) !important;
      border: 1px solid var(--accented) !important;
      color: #003e73;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.self-suggest {
  background-color: #e33131;
  top: 0;
  left: 0;
  padding: 2px 4px;
  border-bottom-right-radius: 5px;
  font-size: 80%;
}

.dialog {
  textarea {
    padding: 5px;
    background-color: #eee;
    border-color: #eee;
    border-radius: 3px;
    outline: none;
    border: none;
    min-width: 300px;
    height: 75px;
    resize: vertical;

    &.textarea-border-red {
      border: 2px solid #e33131;
    }
  }
}
</style>
